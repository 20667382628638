<template>
  <v-layout wrap justify-center id="cf">
    <ServerError v-if="ServerError" />
    <vue-element-loading
      :active="appLoading"
      :is-full-screen="true"
      background-color="#FFFFFF"
      color="blue"
      spinner="mini-spinner"
    />
    <v-flex xs12 lg11 pt-6>
      <v-layout wrap justify-center>
        <v-flex xs12>
          <v-card
            class="mx-auto elevation-1 pa-6"
            max-width="1088px"
            align="center"
            outlined
          >
            <v-layout wrap>
              <v-flex
                xs12
                sm7
                md6
                lg6
                pb-2 pb-md-0
                class="text-left nsbold"
                style="font-weight: 700; font-size: 20px"
                >Category List
              </v-flex>
              <v-flex
                xs12
                sm3
                md4
                lg4
                pb-2 pb-md-0
                align-self-center
                class="text-left nsregular"
              >
                <v-text-field
                  v-model="keyword"
                  dense
                  class="rounded-xl"
                  label="Search"
                  outlined
                  hide-details
                  clearable
                ></v-text-field>
              </v-flex>
              <v-flex xs2 sm2 md2 pb-0 pb-sm-2 pb-md-0  px-1 align-self-center>
                <!-- <v-card rounded outlined  style="cursor: pointer" color="transparent" @click="adddialogue = true"> -->
                <v-btn
                  width="100px"
                  dark
                  color="#766BC0"
                  class="rounded-lg"
                  @click="adddialogue = true"
                >
                  <v-icon x-small>mdi-plus</v-icon>
                  <span
                    class="text-left nsregular"
                    style="font-weight: 400; font-size: 12px"
                    >Add</span
                  ></v-btn
                >
                <!-- </v-card> -->
              </v-flex>
            </v-layout>
            <v-layout py-3>
              <v-flex
                xs2
                class="text-left nsbold"
                style="font-weight: 700; font-size: 18px"
              >
                <span>No.</span>
                
              </v-flex>
              <v-flex
                xs9
                class="text-left nsbold"
                style="font-weight: 600; font-size: 18px"
              >
                <span>Name</span>
              </v-flex>
              <!-- <v-flex
                xs3
                class="text-left nsbold"
                style="font-weight: 600; font-size: 18px"
              >
                <span>Type</span>
              </v-flex> -->
            </v-layout>
            <v-layout
              v-for="(item, i) in student"
              :key="i"
              py-2
              :style="i % 2 == 0 ? 'background-color:#FAFAFA' : ''"
            >
              <v-flex
                xs2
                class="text-left regular"
                style="font-weight: 400; font-size: 18px"
              >
                <!-- <span>{{ i + 1 }}</span> -->
                <span v-if="page == 1">
                          {{ i + 1 }}
                        </span>
                        <span v-else>
                          {{ i+1+(20*(page-1)) }}  
                        </span>
              </v-flex>
              <v-flex
                xs9
                class="text-left nsregular"
                style="font-weight: 400; font-size: 18px"
              >
                <span>{{ item.itemName }}</span>
                <span>({{ item.quantityType }})</span>
              </v-flex>
              <!-- <v-flex
                xs4
                class="text-left nsregular"
                style="font-weight: 400; font-size: 18px"
              >
                <span>{{ item.itemCategory.itemCategoryName }}</span>
              </v-flex> -->
              <!-- {{item}} -->
              <v-flex xs1 v-if="item">
              
                <span
                  ><v-icon
                    style="cursor: pointer"
                    @click="(curItem = item), (editdialogue = true)"
                    >mdi-circle-edit-outline</v-icon
                  ></span
                >
              </v-flex>
              <v-flex xs1>
                <span
                  ><v-icon
                    style="cursor: pointer"
                    @click="(dialoge = true), (curId = item._id)"
                    >mdi-delete-outline</v-icon
                  ></span
                >
              </v-flex>
              <v-divider></v-divider>
            </v-layout>
          </v-card>
        </v-flex>
      </v-layout>

      <v-dialog width="400px" v-model="dialoge">
        <v-card width="400px">
          <v-toolbar dark color="#766BC0" dense flat class="body-2">
            <v-toolbar-title> Confirm Delete </v-toolbar-title>
          </v-toolbar>
          <v-card-text class="pa-4 black--text"
            >Are you sure you want to delete this record?
          </v-card-text>
          <v-card-actions class="pt-3">
            <v-spacer></v-spacer>
            <v-btn
              color="grey"
              text
              class="body-2 font-weight-bold"
              @click="dialoge = false"
              >Cancel</v-btn
            >
            <v-btn
              color="#766BC0"
              class="body-2 font-weight-bold"
              outlined
              @click="deleteCategory()"
              >OK</v-btn
            >
          </v-card-actions>
        </v-card>
      </v-dialog>
      <v-form ref="form" v-model="valid">
        <v-dialog width="400px" v-model="editdialogue">
          <v-card width="400px">
            <v-toolbar dark color="#766BC0" dense flat class="body-2">
              <v-toolbar-title> Edit Item </v-toolbar-title>
            </v-toolbar>
          
            <v-layout wrap v-if="curItem">
              <v-flex xs12 px-4 pb-2 pt-10>
                <v-text-field
                  v-model="curItem.itemName"
                  outlined
                  dense
                  :rules="[(v) => !!v || 'This field is required']"
                ></v-text-field>
              </v-flex>
              <v-flex xs12 px-4 pb-2>
               
                <v-select
                  :items="quantitytypes"
                  v-model="curItem.quantityType"
                  clearable
                  label="Item Type"
                  outlined
                  dense
                  :rules="[(v) => !!v || 'This field is required']"
                ></v-select>
              </v-flex>
              <v-flex xs12 px-4 pb-2 v-if="curItem.quantityType == 'Weight'">
                <v-select
                  :items="weightType"
                  v-model="curItem.productType"
                  clearable
                  label="Type"
                  item-text="itemCategoryName"
                  item-id="_id"
                  outlined
                  :rules="[(v) => !!v || 'This field is required']"
                  dense
                ></v-select>
              </v-flex>
              <v-flex xs12 px-4 pb-2 v-if="curItem.quantityType == 'Litres'">
                <v-select
                  :items="litersType"
                  v-model="curItem.productType"
                  clearable
                  label="Type"
                  item-text="itemCategoryName"
                  item-id="_id"
                  outlined
                  dense
                ></v-select>
              </v-flex>
              <!-- <v-flex xs12 px-4 pb-2 v-if="curItem.quantityType=='Count'">
            <v-select
              :items="litersType"
              v-model="curItem.productType"
              clearable
              label="Type"
              item-text="itemCategoryName"
              item-id="_id"
              outlined
              dense
            ></v-select>
            </v-flex> -->
            </v-layout>
            <v-card-actions class="pt-3">
              <v-spacer></v-spacer>
              <v-btn
                color="grey"
                text
                class="body-2 font-weight-bold"
                @click="editdialogue = false"
                >Cancel</v-btn
              >
              <v-btn
                color="#766BC0"
                class="body-2 font-weight-bold"
                outlined
                @click="editCategory()"
                >OK</v-btn
              >
            </v-card-actions>
          </v-card>
        </v-dialog>
      </v-form>
      <v-form ref="form">
        <v-dialog  width="400px" v-model="adddialogue">
          <v-card width="400px">
            <v-toolbar dark color="#766BC0" dense flat class="body-2">
              <v-toolbar-title> Add Item </v-toolbar-title>
            </v-toolbar>

            <v-layout wrap>
              <v-flex xs12 px-4 pt-10 pb-2>
                <v-text-field
                  autofocus
                  label="Name"
                  v-model="name"
                  outlined
                  dense
                  :rules="[(v) => !!v || 'This field is required']"
                ></v-text-field>
              </v-flex>
              <!-- <v-flex xs12 px-4 pb-2>
             
            <v-select
              :items="typeList"
              v-model="typee"
              clearable
              label="Type"
              item-text="itemCategoryName"
              item-value="_id"
              outlined
              @input="search()"
              dense
            ></v-select>
            </v-flex> -->
              <v-flex xs12 px-4 pb-2>
                <v-select
                  :items="quantitytypes"
                  v-model="quantityType"
                  clearable
                  label="Select Type"
                  outlined
                  :rules="[(v) => !!v || 'This field is required']"
                  dense
                ></v-select>
              </v-flex>
              <v-flex xs12 px-4 pb-2 v-if="quantityType == 'Weight'">
                <v-select
                  :items="weightType"
                  v-model="productType"
                  clearable
                  label="Type"
                  item-text="itemCategoryName"
                  item-id="_id"
                  outlined
                  :rules="[(v) => !!v || 'This field is required']"
                  dense
                ></v-select>
              </v-flex>
              <v-flex xs12 px-4 pb-2 v-if="quantityType == 'Litres'">
                <v-select
                  :items="litersType"
                  v-model="productType"
                  clearable
                  label="Type"
                  item-text="itemCategoryName"
                  item-id="_id"
                  outlined
                  dense
                ></v-select>
              </v-flex>
            </v-layout>
            <v-card-actions class="pt-3">
              <v-spacer></v-spacer>
              <v-btn
                color="grey"
                text
                class="body-2 font-weight-bold"
                @click="adddialogue = false"
                >Cancel</v-btn
              >
              <v-btn
                color="#766BC0"
                class="body-2 font-weight-bold"
                outlined
                @click="addCategory()"
                >OK</v-btn
              >
            </v-card-actions>
          </v-card>
        </v-dialog>
      </v-form>
      <v-layout wrap>
          <v-flex xs12 pt-4>
            <v-pagination small color="#766BC0" v-model="page" :length="Pagelength">
            </v-pagination>
          </v-flex>
        </v-layout>
     <v-snackbar
        :timeout="3000"
        :value="true"
        right
        color="red"
        v-model="showsnackbar"
      >
        <span style="font-size: 20px"> {{ msg }}</span>
      </v-snackbar>
    </v-flex>
  </v-layout>
</template>    
<script>
import axios from "axios";
export default {
  data() {
    return {
      student: [],
       page: 1,
      Pagelength: 0,
      dialoge: false,
      editdialogue: false,
      adddialogue: false,
      name: null,
      curId: null,
      curItem: null,
      showsnackbar: false,
      msg: null,
      appLoading: false,
      ServerError: false,
      typeList: null,
      typee: "",
      quantitytypes: ["Count", "Weight", "Litres"],
      quantityType: null,
      productType: "",
      keyword: null,
      valid: true,

      weightType: ["Gram", "Kilo gram"],
      litersType: ["Milli Litre", "Litre"],
      rules: {
        required: (value) => !!value || "Required.",
        counter: (value) => value.length <= 20 || "Max 20 characters",
        phoneRules: [
          (v) => !!v || "phone is required",
          (v) =>
            (v && v.length <= 10) || "number must be less than 11 characters",
          (v) =>
            (v && v.length >= 10) ||
            "number must be greater than 10 characters",
          (v) => /^\d{10}$/.test(v) || "phone number must be a number",
        ],
        email: (value) => {
          const pattern =
            /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
          return pattern.test(value) || "Invalid e-mail.";
        },
      },
    };
  },
  mounted() {
    this.getData();
    this.getType();
  },
  watch: {
     page() {
      // this.getData();
      this.getData();
    },
    keyword() {
      this.getData();
    },
  },
  methods: {
    getData() {
      this.appLoading = true;
      axios({
        method: "get",
        url: "/item/getalllist",
        headers: {
          token: localStorage.getItem("token"),
        },
        params: {
          id: this.$route.query.id,
          keyword: this.keyword,
          page: this.page,
          limit: 20,
        },
      })
        .then((response) => {
          this.appLoading = false;
          if (response.data.status == true) {
            this.student = response.data.data;
            this.Pagelength = Math.ceil(response.data.totalLength / 20);
          } else {
            this.snackbar = true;
            this.msg = response.data.msg;
          }

          // this.msg = response.data.msg;

          // this.snackbar = true;
        })
        .catch((err) => {
          this.appLoading = false;
          console.log(err);
        });
    },
    //delete button
    deleteCategory() {
      axios({
        url: "/item/remove",
        method: "POST",
        headers: {
          token: localStorage.getItem("token"),
        },
        data: {
          id: this.curId,
        },
      })
        .then((response) => {
          this.delete = false;
          this.dialoge = false;
          this.appLoading = false;
          if (response.data.status) {
            this.msg = "Delete Sucessfully";
            // this.$router.go(-1)
            this.showsnackbar = true;
            this.getData();
          } else {
            this.msg = response.data.msg;
            this.showsnackbar = true;
          }
        })
        .catch((err) => {
          this.appLoading = false;
          this.ServerError = true;
          console.log(err);
        });
    },
    //call item type
    getType() {
      axios({
        method: "get",
        url: "/item/category/getalllist",
        headers: {
          token: localStorage.getItem("token"),
        },
        params: {
          id: this.cls,
        },
      })
        .then((response) => {
          if (response.data.status == true) {
            this.typeList = response.data.data;
          }
        })
        .catch((err) => {
          console.log(err());
        });
    },
    //add button
    addCategory() {
      if (this.$refs.form.validate()) {
        axios({
          url: "/item/add",
          method: "POST",
          headers: {
            token: localStorage.getItem("token"),
          },
          data: {
            itemName: this.name,
            quantityType: this.productType,
          },
        })
          .then((response) => {
            this.adddialogue = false;
            this.appLoading = false;
            if (response.data.status) {
              this.msg = response.data.msg;
              this.showsnackbar = true;
              this.getData();
              this.name=null
              this.productType=null
              this.quantityType=null
              this.$refs.form.resetValidation()
            } else {
              this.msg = response.data.msg;
              this.showsnackbar = true;
            }

            this.name = null;
          })
          .catch((err) => {
            this.appLoading = false;
            this.ServerError = true;
            console.log(err);
          });
      }
    },
    //edit button
    editCategory() {
      if (this.$refs.form.validate())
      {
        // if(this.curItem.quantityType == 'Count')
        // this.curItem.productType='Count'
 
        axios({
          url: "/item/edit",
          method: "POST",
          headers: {
            token: localStorage.getItem("token"),
          },
          data: {
            id: this.curItem._id,
            itemName: this.curItem.itemName,
            quantityType: this.curItem.quantityType,
            // productType: this.curItem.productType,
          },
        })
          .then((response) => {
            this.editdialogue = false;
            this.appLoading = false;
            if (response.data.status) {
              this.msg = "Updated Sucessfully";
              // this.$router.go(-1)
              this.showsnackbar = true;
              this.getData();
            } else {
              this.msg = response.data.msg;
              this.showsnackbar = true;
            }
          })
          .catch((err) => {
            this.appLoading = false;
            this.ServerError = true;
            console.log(err);
          });

               }
    },
  },
};
</script>